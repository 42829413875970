<template>
    <div>
        <!-- @slot Block before input element -->
        <slot name="before" />

        <input
            v-if="!readOnly"
            ref="field"
            type="tel"
            class="input"
            :class="inputClasses"
            :style="computeWidth && { width }"
            :name="name"
            :value="maskedValue"
            :disabled="disabled"
            @input="handleInput"
            @blur="handleBlur"
            @focus="handleFocus"
        />

        <div
            v-if="readOnly"
            class="whitespace-no-wrap"
            :class="amountClass"
        >
            <slot :maskedValue="maskedValue">
                {{ maskedValue }}
            </slot>
        </div>

        <!-- @slot Block after input element -->
        <slot name="after" />
    </div>
</template>

<script>
import { mask as amountMask, getNegative as getAmountNegative, getOnlyDigits, convertToCurrency } from '@/utils/Amount';

export default {
    name: 'AmountInput',
    props: {
        /**
         * Input's 'value' attribute
         */
        modelValue: {
            type: [String, Number],
            default: 0,
        },

        /**
         * Input's 'name' attribute
         */
        name: {
            type: String,
        },

        /**
         * Whether input is readonly
         */
        readOnly: {
            type: Boolean,
        },

        /**
         * Disabled state
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * String displayed before input text
         */
        prefix: {
            type: String,
            default: '',
        },

        /**
         * String displayed after input text
         */
        suffix: {
            type: String,
            default: '',
        },

        /**
         * Thousands separator symbol
         */
        delimeter: {
            type: String,
            default: ',',
        },

        /**
         * Symbol used to separate the integer part from the fractional part
         */
        decimal: {
            type: String,
            default: '.',
        },

        /**
         * Amount precision
         */
        precision: {
            type: Number,
            default: 2,
        },

        /**
         * Whether the negative value may be entered
         */
        allowNegative: {
            type: Boolean,
            default: false,
        },

        /**
         * Maximum allowable value
         */
        max: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER,
        },

        /**
         * Minimum allowable value
         */
        min: {
            type: Number,
            default: Number.MIN_SAFE_INTEGER,
        },

        /**
         * Input element class
         */
        amountClass: {
            type: [String, Array],
        },

        /**
         * Whether component should be calculated dynamically according to it's max value
         */
        computeWidth: {
            type: Boolean,
            default: true,
        },

        /**
         * Minimum input size (in characters)
         */
        minSize: {
            type: Number,
        },
    },

    emits: [
        /**
         * Emitted when the control changes its value
         */
        'update:modelValue',
        /**
         * Emitted on focus
         */
        'focus',
        /**
         * Emitted on blur
         */
        'blur',
    ],

    computed: {
        inputClasses() {
            const classes = [];
            if (this.computeWidth) {
                classes.push('form-input--content-box');
            }
            if (this.amountClass) {
                if (Array.isArray(this.amountClass)) {
                    this.amountClass.forEach(c => classes.push(c));
                } else {
                    classes.push(this.amountClass);
                }
            }
            return classes;
        },

        maskedValue() {
            if (this.readOnly) {
                return this.mask(this.modelValue);
            }

            return this.mask(this.range(this.modelValue));
        },

        width() {
            const maxSize = Math.max(this.mask(this.min).length, this.mask(this.max).length);
            const size = this.minSize ? Math.max(this.minSize, maxSize) : maxSize;

            return `${size}ch`;
        },
    },

    methods: {
        mask: function (value = 0) {
            return amountMask(value, {
                precision: this.precision,
                delimeter: this.delimeter,
                decimal: this.decimal,
                prefix: this.prefix,
                suffix: this.suffix,
            });
        },

        unmask: function (maskedValue) {
            const negative = this.getNegative(maskedValue);
            let digits = getOnlyDigits(maskedValue) || 0;
            const currency = convertToCurrency(digits, this.precision);

            return negative * currency;
        },

        setCaretPosition(el, position) {
            function setSelectionRange() {
                el.setSelectionRange(position, position);
            }

            if (el === document.activeElement) {
                setSelectionRange();
                setTimeout(setSelectionRange, 1);
            }
        },

        getNegative(maskedValue) {
            if (!this.allowNegative) {
                return 1;
            }

            return getAmountNegative(maskedValue);
        },

        checkForMax(value, max, otherwise) {
            return typeof max === 'number' && value <= max ? value : otherwise;
        },

        checkForMin(value, min, otherwise) {
            return typeof min === 'number' && value >= min ? value : otherwise;
        },

        range(value) {
            return this.checkRange(value, { max: this.max, min: this.min }, { maxVal: this.max, minVal: this.min });
        },

        checkRange(value, { min, max }, otherwise) {
            const minOtherwise = otherwise.minVal || otherwise;
            const maxOtherwise = otherwise.maxVal || otherwise;

            return this.checkForMax(this.checkForMin(value, min, minOtherwise), max, maxOtherwise);
        },

        handleBlur() {
            this.$emit('blur');
        },

        handleFocus() {
            const input = this.$refs.field;
            const value = input.value;
            const position = value.length - this.suffix.length;

            this.setCaretPosition(input, position);

            this.$emit('focus');
        },

        handleInput: function (event) {
            const value = event.target.value;
            const input = this.$refs.field;
            const rawValue = this.unmask(value);
            const rangedRawValue = this.checkRange(rawValue, { max: this.max, min: this.min }, this.modelValue || 0);
            const maskedValue = this.mask(rangedRawValue);
            let caretPosition = value.length - input.selectionEnd;

            caretPosition = Math.max(caretPosition, this.suffix.length);
            caretPosition = maskedValue.length - caretPosition;
            caretPosition = Math.max(caretPosition, this.prefix.length + 1);

            input.value = maskedValue;

            this.setCaretPosition(input, caretPosition);

            this.$emit('update:modelValue', rangedRawValue);
        },
    },
};
</script>
