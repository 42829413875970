<template>
    <div
        class="w-full mt-6 rounded-xl px-5 py-5.5"
        :class="{ 'bg-purple-100 border-violet-50 border border-solid': !backdrop }"
    >
        <div class="grid sm:grid-cols-8 w-full items-center gap-x-3">
            <div class="col-span-8 sm:col-span-4 mb-3 sm:mb-0">
                <div class="font-ffdin">
                    <label class="text-sm text-violet-800 tracking-wider font-medium">
                        {{ $t('payments.current_balance') }}
                    </label>
                    <div
                        class="text-purple-600 text-lg sm:text-xl md:text-2xl font-bold mt-1 md:mt-2.5"
                        data-test="balance"
                    >
                        {{ balance }}
                    </div>
                </div>
            </div>
            <div class="col-span-8 sm:col-span-4">
                <div class="font-ffdin">
                    <label class="text-sm text-violet-800 tracking-wider font-medium">
                        {{ $t('payments.list_columns.date_due') }}
                    </label>
                    <div
                        class="data-large text-purple-600 text-lg sm:text-xl md:text-2xl font-medium mt-1 md:mt-2.5"
                        data-test="date"
                    >
                        {{ date }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BalanceInfo',

    props: {
        balance: {
            type: String,
            required: true,
        },

        date: {
            type: String,
            required: true,
        },

        backdrop: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
