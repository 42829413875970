<template>
    <teleport to="#app">
        <div
            class="tpp-widget-wrapper absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center z-50"
            :style="styles"
        >
            <div class="page-title absolute top-5">{{ title }}</div>
            <div class="tpp-widget-content">
                <icon
                    name="close"
                    class="absolute z-75 top-5 right-5 h-6 w-6 cursor-pointer"
                    @click="setTPPWidgetVisibility(false)"
                />
            </div>
        </div>
    </teleport>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { mapActions } from 'vuex';

export default {
    name: 'TPPWidgetWrapper',

    components: { Icon },

    props: {
        title: {
            type: String,
            default: '',
        },

        styles: {
            type: Object,
            default: () => {},
        },
    },

    mounted() {
        this.addEventHandlers();
    },

    unmounted() {
        this.removeEventHandlers();
    },

    methods: {
        ...mapActions({
            setTPPWidgetVisibility: 'accounting/setTPPWidgetVisibility',
        }),

        handleClickOutside(e) {
            if (!this.$el.contains(e.target)) {
                this.setTPPWidgetVisibility(false);
            }
        },

        addEventHandlers() {
            document.addEventListener('click', this.handleClickOutside);
        },

        removeEventHandlers() {
            document.removeEventListener('click', this.handleClickOutside);
            this.setTPPWidgetVisibility(false);
        },
    },
};
</script>

<style scoped>
.tpp-widget-wrapper {
    @apply xl:w-1/3 md:w-2/4 md:rounded-2xl bg-white;
    width: 507px;
}
</style>
